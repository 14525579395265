import { request, getHost, getHeaders } from './utils';

export const getResultDetail = (id, rid) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/newsletter/${id}/result/${rid}`,
    headers: getHeaders()
  };
  return request(options);
};

export const updateResultDetail = (id, rid, memo) => {
  const options = {
    method: 'put',
    url: `${getHost()}/api/v1/newsletter/${id}/result/${rid}`,
    headers: getHeaders(),
    data: {
      memo
    }
  };
  return request(options);
};

export const updateLatestActionTime = (id, rid) => {
  const options = {
    method: 'put',
    url: `${getHost()}/api/v1/newsletter/${id}/result/${rid}`,
    headers: getHeaders()
  };
  return request(options);
};

export const getResultSummary = (id, date) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/newsletter/${id}/summary`,
    headers: getHeaders(),
    data: {
      date
    }
  };
  return request(options);
};

export const getResultTopics = (id, data, params) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/newsletter/${id}/post`,
    headers: getHeaders(),
    params: {
      size: 20,
      ...params
    },
    data: {
      excludeNotSend: false,
      ...data
    }
  };
  return request(options);
};

export const getResultTopicsByPage = (id, params) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/vocService/newsletter/${id}/post`,
    headers: getHeaders(),
    params
  };
  return request(options);
};

export const updateTopicAttribute = (id, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/newsletter/${id}/attr`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getVolumeChartData = (id, date) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/newsletter/${id}/volume`,
    headers: getHeaders(),
    data: {
      date
    }
  };
  return request(options);
};

export const getFeatureData = (id, date) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/newsletter/${id}/feature`,
    headers: getHeaders(),
    data: {
      date
    }
  };
  return request(options);
};

export const manualSendEmail = (id, rid) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/v1/newsletter/${id}/result/${rid}/send`,
    headers: getHeaders()
  };
  return request(options);
};

export const getTopicsByToken = (token) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/v1/newsletter/topic/${token}`,
    headers: getHeaders()
  };
  return request(options);
};

export const getTopicsPagingByToken = (token, params) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/newsletter/topic/${token}`,
    headers: getHeaders(),
    params: {
      ...params,
      size: 20
    }
  };
  return request(options);
};

export const refreshAutoSummary = (id, rid) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/v1/newsletter/${id}/result/${rid}/regenerate-auto-summary`,
    headers: getHeaders()
  };
  return request(options);
};

export const regenerateAiComment = (id, tid, date) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/newsletter/${id}/eval`,
    headers: getHeaders(),
    data: {
      subjectId: tid,
      date
    }
  };
  return request(options);
};

export const getAiCommentStatus = (id, tid, date) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/newsletter/${id}/eval/log`,
    headers: getHeaders(),
    data: {
      subjectId: tid,
      date
    }
  };
  return request(options);
};
