import { message } from 'antd';
import { toJS, makeObservable, observable, action, computed, runInAction } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import is from 'is_js';

import {
  TOPIC_ATTRIBUTE_TYPE,
  TOPIC_ATTRIBUTE_TYPE_TC,
  DEFAULT_SENTIMENT_OPTIONS,
  DEFAULT_TOPIC_ATTRIBUTE_OPTIONS,
  DEFAULT_REPLY_OPTIONS,
  DEFAULT_DEPARTMENT_OPTIONS,
  TAG_ACTIONS
} from 'src/constants/normal';

import { SALES_TEAM_ID, SERVER_STATUS } from 'src/constants/config';

import i18n from 'src/i18n';

import mainStore from 'src/stores/mainStore';

import ProjectService from 'src/services/project';
import SSOService from 'src/services/sso';
import EDMService from 'src/services/edm';

import AttributeTagViewModel from './components/AttributeTag/viewModel';
import TopicModalViewModel from './components/topicModal/viewModel';
import TopicContentBarViewModel from './components/topicContentBar/viewModel';
import CheckDeleteModalViewModel from './components/checkDeleteModal/viewModel';

export default class EDMEditDrawerViewModel {
  parent = null;
  @observable isOpen = false;
  @observable readOnly = false;

  @observable id = null;
  @observable name = '';
  @observable departmentList = [];
  @observable selectedDepartment = null;
  @observable memberList = [];
  @observable selectedMember = null;
  @observable projectList = [];
  @observable selectedProject = null;
  @observable availableDate = [dayjs(), dayjs()];
  @observable selectedDate = [null, null];

  @observable selectedTemplate = 'edm';

  // ? added 2024/11/01
  @observable autoSummary = false;
  // ? added 2025/03/28
  @observable aiComment = false;

  @observable projectSearchAngle = 'brand';

  @observable brandList = [];
  @observable selectedBrand = null;

  @observable featureSetList = [];
  @observable selectedFeatureSet = null;

  topicModalViewModel = new TopicModalViewModel(this);
  checkDeleteModalViewModel = new CheckDeleteModalViewModel(this);

  @observable topics = [];

  @observable collaboratorList = [];
  @observable selectedCollaborators = [];

  @observable tagPool = [];

  @observable subject = '';

  @observable receiverInput = '';
  @observable receiver = [];

  @observable selectedFrequency = [];

  @observable autoSend = false;

  @observable dailyNoon = true;
  @observable dailyEvening = true;
  // ? added 2024/12/09
  @observable dailyNight = true;


  @observable isOnCheck = false;

  @observable valid = {
    nameDuplicate: true,
    nameEmpty: true,
    projectId: true,
    date: true,
    template: true,
    mainLevel1: true,
    feature: true,
    topics: true,
    subject: true,
    receivers: true,
    frequency: true
  };

  @computed get formattedProjectsList() {
    return this.projectList.map((project) => ({ value: project.id, label: `${project.name}(${project.id})` }));
  }

  @computed get sentimentTags() {
    return this.tagPool.filter((tag) => tag.type === TOPIC_ATTRIBUTE_TYPE.Rating && tag.action !== TAG_ACTIONS.Delete);
  }
  @computed get attributeTags() {
    return this.tagPool.filter((tag) => tag.type === TOPIC_ATTRIBUTE_TYPE.Topic && tag.action !== TAG_ACTIONS.Delete);
  }
  @computed get replyTags() {
    return this.tagPool.filter((tag) => tag.type === TOPIC_ATTRIBUTE_TYPE.Suggestion && tag.action !== TAG_ACTIONS.Delete);
  }
  @computed get departmentTags() {
    return this.tagPool.filter((tag) => tag.type === TOPIC_ATTRIBUTE_TYPE.Department && tag.action !== TAG_ACTIONS.Delete);
  }

  @computed get showTopics() {
    return this.topics.filter((topic) => topic.action !== TAG_ACTIONS.Delete);
  }

  @computed get canSubmit() {
    return !this.isOnCheck || Object.values(this.valid).every((item) => !!item);
  }

  constructor(parent) {
    makeObservable(this);

    this.init(parent);
  }

  @action init = (parent) => {
    this.parent = parent;
  };

  @action didMount = async () => {
    await Promise.all([
      this.getTeamList(),
      this.getCollaborator()
    ]);
  };

  @action resetEmpty = async () => {
    this.id = null;
    this.name = '';
    this.selectedDepartment = null;
    this.selectedMember = null;
    this.selectedProject = null;
    this.selectedDate = [null, null];
    this.selectedTemplate = 'edm';
    this.selectedBrand = null;
    this.selectedFeatureSet = null;
    this.selectedCollaborators = [];
    this.subject = '';
    this.receiver = [];
    this.selectedFrequency = [];
    this.autoSend = false;
    this.dailyNoon = true;
    this.dailyEvening = true;
    // ? added 2024/12/09
    this.dailyNight = true;

    this.topics = [];
    // > init tag
    this.tagPool = [
      ...DEFAULT_SENTIMENT_OPTIONS.map((el) => new AttributeTagViewModel({
        id: null,
        action: TAG_ACTIONS.Create,
        type: TOPIC_ATTRIBUTE_TYPE.Rating,
        value: el.label
      }, this)),
      ...DEFAULT_TOPIC_ATTRIBUTE_OPTIONS.map((el) => new AttributeTagViewModel({
        id: null,
        action: TAG_ACTIONS.Create,
        type: TOPIC_ATTRIBUTE_TYPE.Topic,
        value: el.label
      }, this)),
      ...DEFAULT_REPLY_OPTIONS.map((el) => new AttributeTagViewModel({
        id: null,
        action: TAG_ACTIONS.Create,
        type: TOPIC_ATTRIBUTE_TYPE.Suggestion,
        value: el.label
      }, this)),
      ...DEFAULT_DEPARTMENT_OPTIONS.map((el) => new AttributeTagViewModel({
        id: null,
        action: TAG_ACTIONS.Create,
        type: TOPIC_ATTRIBUTE_TYPE.Department,
        value: el.label
      }, this))
    ];

    this.isOnCheck = false;
    this.valid = {
      nameDuplicate: true,
      nameEmpty: true,
      projectId: true,
      date: true,
      template: true,
      mainLevel1: true,
      feature: true,
      topics: true,
      subject: true,
      receivers: true,
      frequency: true
    };
  };


  @action resetExist = async (id, readOnly) => {
    try {
      if (mainStore.checkIsInQueue('openExistEdm')) {
        return;
      }
      mainStore.setLoading('openExistEdm');
      this.id = id;
      const detail = await EDMService.getEDMDetail(id);
      const {
        name,
        projectId,
        emailStartDate,
        emailEndDate,
        template,
        mainBrandId,
        featureSetId,
        topics,
        collaboratorIds,
        tags,
        subject,
        receivers,
        frequency,
        isAutoSend,
        isAutoSendAtEvening,
        isAutoSendAtNoon,
        // ? added 2024/12/09
        isAutoSendAtNight,
        // ? added 2024/11/01
        autoSummary,
        // ? added 2025/03/28
        aiComment
      } = detail;

      // > clean and reset project list.
      await this.onProjectChange(projectId);

      const receiversSet = new Set(receivers);

      runInAction(() => {
        this.name = name;
        this.selectedDate = [dayjs(emailStartDate).tz('Asia/Taipei'), dayjs(emailEndDate).tz('Asia/Taipei')];
        this.selectedTemplate = template;
        this.selectedBrand = mainBrandId;
        this.selectedFeatureSet = featureSetId;
        this.selectedCollaborators = collaboratorIds;
        this.subject = subject;
        this.receiver = Array.from(receiversSet);
        this.selectedFrequency = frequency;
        this.autoSend = isAutoSend;
        this.dailyNoon = isAutoSendAtNoon;
        this.dailyEvening = isAutoSendAtEvening;
        // ? added 2024/12/09
        this.dailyNight = isAutoSendAtNight;
        // ? added 2024/11/01
        this.autoSummary = autoSummary;
        // ? added 2025/03/28
        this.aiComment = aiComment;
        this.tagPool = tags.filter((el) => !el.deletedAt).map((tag) => new AttributeTagViewModel(tag, this));
        this.topics = topics.map((topic) => new TopicContentBarViewModel(topic, this));

        this.isOnCheck = false;
        this.valid = {
          nameDuplicate: true,
          nameEmpty: true,
          projectId: true,
          date: true,
          template: true,
          mainLevel1: true,
          feature: true,
          topics: true,
          subject: true,
          receivers: true,
          frequency: true
        };

        this.readOnly = readOnly;
        if (readOnly) {
          message.warning(i18n.t('edm_setting_drawer_message_analyzing_warning'));
        }
      });



    } catch (error) {
      message.error(i18n.t('api_get_edm_detail_error'));
    } finally {
      mainStore.setLoadingComplete('openExistEdm');
    }
  };

  @action onOpen = async (id, readOnly = false) => {
    await this.resetEmpty();
    if (!id) {
      // > here set default department and get project list
      await this.onDepartmentSelect(SALES_TEAM_ID[SERVER_STATUS]);
    } else {
      await this.resetExist(id, readOnly);
    }
    runInAction(() => {
      this.isOpen = true;
    });
  };

  @action onClose = () => {
    this.isOpen = false;
  };

  @action onNameChange = (e) => {
    this.valid.nameEmpty = true;
    this.valid.nameDuplicate = true;
    this.name = e.target.value;
  };

  // > project related
  @action getTeamList = async () => {
    try {
      if (mainStore.checkIsInQueue('teamList')) {
        return;
      }
      mainStore.setLoading('teamList');
      const { list } = await SSOService.getTeamList();
      runInAction(() => {
        this.departmentList = [...list];
      });
    } catch (error) {
      message.error(i18n.t('api_get_team_list_error'));
    } finally {
      mainStore.setLoadingComplete('teamList');
    }
  };

  @action getProjectList = async () => {
    try {
      if (mainStore.checkIsInQueue('projectList')) {
        return;
      }
      mainStore.setLoading('projectList');
      const { list } = await ProjectService.getProjectsList({
        ...(
          this.selectedDepartment
            ? {
              teamId: this.selectedDepartment
            }
            : {}
        ),
        ...(
          this.selectedMember
            ? {
              userId: this.selectedMember
            }
            : {}
        )
      });

      runInAction(() => {
        this.projectList = [...list];
      });
    } catch (error) {
      message.error(i18n.t('api_get_project_list_error'));
    } finally {
      mainStore.setLoadingComplete('projectList');
    }
  };
  @action onDepartmentSelect = async (value) => {
    this.selectedDepartment = value;
    this.selectedMember = null;
    this.selectedProject = null;

    if (!value) {
      this.memberList = [];
      await this.getProjectList();
      return;
    }

    await Promise.all([
      this.getMemberByTeamId(),
      this.getProjectList()
    ]);
  };

  @action getMemberByTeamId = async () => {
    try {
      if (mainStore.checkIsInQueue('projectOwnerList')) {
        return;
      }
      mainStore.setLoading('projectOwnerList');
      const { list } = await SSOService.getMembersByTeamId(this.selectedDepartment);
      runInAction(() => {
        this.memberList = [...list];
      });
    } catch (error) {
      message.error(i18n.t('api_get_project_owner_list_error'));
    } finally {
      mainStore.setLoadingComplete('projectOwnerList');
    }
  };

  @action onMemberChange = async (value) => {
    this.selectedMember = value;
    this.selectedProject = null;

    await this.getProjectList();
  };

  @action onProjectChange = async (value) => {
    this.valid.projectId = true;
    this.selectedProject = value;
    if (!value) {
      return;
    }
    try {
      const {
        meta: { searchAngle },
        autorun: { endDate },
        owner: { team, user }
      } = await ProjectService.getProjectDetailById(value);
      runInAction(() => {
        this.projectSearchAngle = searchAngle;
      });

      this.availableDate = [dayjs().tz('Asia/Taipei').startOf('day'), dayjs(endDate).tz('Asia/Taipei').endOf('day')];
      const availableStartAt = this.availableDate[0].valueOf();
      const availableEndAt = this.availableDate[1].valueOf();
      const nowStartAt = this.selectedDate[0] ? dayjs(this.selectedDate[0]).tz('Asia/Taipei').startOf('day').valueOf() : null;
      const nowEndAt = this.selectedDate[1] ? dayjs(this.selectedDate[1]).tz('Asia/Taipei').endOf('day').valueOf() : null;

      if (nowStartAt && (nowStartAt < availableStartAt || nowStartAt > availableEndAt)) {
        this.selectedDate = [null, null];
      }

      if (nowEndAt && (nowEndAt < availableStartAt || nowEndAt > availableEndAt)) {
        this.selectedDate = [null, null];
      }

      this.selectedBrand = null;
      this.selectedFeatureSet = null;
      this.topics = [];
      this.topicModalViewModel.updateProjectId(this.selectedProject, this.projectSearchAngle);

      if (!this.selectedDepartment) {
        this.selectedDepartment = team.id;
        await this.getMemberByTeamId();
        runInAction(() => {
          this.selectedMember = user.id;
        });
      }

      if (this.selectedDepartment && !this.selectedMember) {
        this.selectedMember = user.id;
      }
      await this.getProjectList();
      await this.getProjectDetail();
    } catch (error) {
      message.error(i18n.t('edm_drawer_project_toast_error'));
    }
  };

  @action getProjectDetail = async () => {
    try {
      if (mainStore.checkIsInQueue('projectDetail')) {
        return;
      }
      mainStore.setLoading('projectDetail');
      const [{ featureSetList }, { brand }] = await Promise.all([
        ProjectService.getFeatureSetByProject(this.selectedProject),
        ProjectService.getBrandByProject(this.selectedProject)
      ]);

      runInAction(() => {
        this.brandList = [...brand];
        this.featureSetList = [...featureSetList];
      });
    } catch (error) {
      message.error(i18n.t('api_get_project_detail_error'));
    } finally {
      mainStore.setLoadingComplete('projectDetail');
    }
  };

  @action onDateChange = (date) => {
    this.valid.date = true;
    this.selectedDate = date;
  };

  @action onTemplateSelect = (value) => {
    this.valid.template = true;
    this.selectedTemplate = value;
  };

  @action onAutoSummaryChange = (boolean) => {
    this.autoSummary = boolean;
  };

  // ? added 2025/03/28
  @action onAiCommentChange = (boolean) => {
    this.aiComment = boolean;
  };

  @action onMainBrandSelect = (value) => {
    this.valid.mainLevel1 = true;
    this.selectedBrand = value;
  };

  @action onFeatureSetSelect = (value) => {
    this.valid.feature = true;
    this.selectedFeatureSet = value;
  };

  // > topic related
  @action onTopicSubmit = (topicContent) => {
    this.valid.topics = true;
    if (topicContent.id) {
      let target = this.topics.find((topic) => topic.id === topicContent.id);
      if (!target) {
        return;
      }
      target = {
        ...target,
        ...topicContent
      };
    } else {
      this.topics.push(
        new TopicContentBarViewModel({
          ...topicContent,
          id: uuidv4()
        }, this)
      );
    }
  };

  // > tag related
  @action removeByValue = (targetTag) => {
    this.tagPool = this.tagPool.filter((tag) => {
      return !(tag.type === targetTag.type && tag.name === targetTag.name);
    });
  };

  @action onAddTag = (type) => {
    return (value) => {
      const sameTag = this.tagPool.find((tag) => tag.type === type && tag.name === value && tag.action !== TAG_ACTIONS.Delete);
      if (sameTag) {
        message.error(i18n.t('edm_drawer_setting_topic_add_error_same_name').replace('%type%', TOPIC_ATTRIBUTE_TYPE_TC[type]));
        return false;
      }
      this.tagPool.push(
        new AttributeTagViewModel({
          id: null,
          action: TAG_ACTIONS.Create,
          type,
          value
        }, this)
      );

      message.success(i18n.t('edm_drawer_setting_topic_add_success').replace('%name%', value));
      return true;
    };
  };

  @action checkHasSame = ({ type, name }) => {
    const sameTag = this.tagPool.find((tag) => tag.type === type && tag.name === name && tag.action !== TAG_ACTIONS.Delete);
    if (sameTag) {
      message.error(i18n.t('edm_drawer_setting_topic_add_error_same_name').replace('%type%', TOPIC_ATTRIBUTE_TYPE_TC[type]));
      return true;
    }
    return false;
  };

  // > collaborator related
  @action getCollaborator = async () => {
    try {
      if (mainStore.checkIsInQueue('drawerCollaboratorList')) {
        return;
      }
      mainStore.setLoading('drawerCollaboratorList');
      const { list } = await SSOService.getCollaborators();

      runInAction(() => {
        this.collaboratorList = [...list];
      });
    } catch (error) {
      message.error(i18n.t('api_get_collaborator_error'));
    } finally {
      mainStore.setLoadingComplete('drawerCollaboratorList');
    }
  };

  @action onCollaboratorSelect = (value) => {
    this.selectedCollaborators = value;
  };

  @action onSubjectChange = (e) => {
    this.valid.subject = true;
    this.subject = e.target.value;
  };

  // > receiver related
  @action addReceiver = () => {
    const email = this.receiverInput.trim();
    if (email) {
      const validate = this.textIsEmailValid(email);
      const hasSame = this.receiver.find((item) => item === email);
      if (validate && !hasSame) {
        this.valid.receivers = true;
        this.receiver.push(email);
        message.success(i18n.t('edm_drawer_setting_receiver_setting_success'));
      } else if (validate && hasSame) {
        message.error(i18n.t('edm_drawer_setting_receiver_same_error'));
        return;
      } else {
        message.error(i18n.t('edm_drawer_setting_receiver_error'));
        return;
      }

    }
    this.receiverInput = '';
  };

  textIsEmailValid = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  @action onReceiverChange = (e) => {
    this.receiverInput = e.target.value;
  };

  @action onReceiverKeydown = (e) => {
    if (e.code === 'Enter' || e.keyword === 13) {
      if (e.nativeEvent.isComposing) {
        return;
      }
      this.addReceiver();
    }
  };

  @action onReceiverDeleteClick = (receiver) => {
    this.onCheckDeleteModalOpen({
      type: 'email',
      value: {
        email: receiver
      },
      callback: () => this.onReceiverRemove(receiver)
    });
  };

  @action onReceiverRemove = (receiver) => {
    this.receiver = this.receiver.filter((r) => r !== receiver);
  };

  // > frequency related

  @action onFrequencyChange = (value) => {
    this.valid.frequency = true;
    this.selectedFrequency = value;
    if (this.autoSend && this.selectedFrequency.includes('multiple')) {
      this.dailyNoon = true;
      this.dailyEvening = true;
      // ? added 2024/12/09
      this.dailyNight = true;
    }
  };

  @action onAutoSendChange = (boolean) => {
    this.autoSend = boolean;
    if (this.autoSend && this.selectedFrequency.includes('multiple')) {
      this.dailyNoon = true;
      this.dailyEvening = true;
      // ? added 2024/12/09
      this.dailyNight = true;
    }
  };

  @action onDailyNoonChange = (event) => {
    this.dailyNoon = event.target.checked;
  };

  @action onDailyEveningChange = (event) => {
    this.dailyEvening = event.target.checked;
  };

  // ? added 2024/12/09
  @action onDailyNightChange = (event) => {
    this.dailyNight = event.target.checked;
  };

  // > topic modal related
  onAddNewTopic = () => {
    this.topicModalViewModel.onOpen();
  };

  @action onRemoveTopic = (id) => {
    this.topics = this.topics.filter((topic) => topic.id !== id);
  };

  // > submit related

  @action checkStatus = () => {
    this.isOnCheck = true;

    if (!this.name.trim()) {
      this.valid.nameEmpty = false;
    }

    if (!this.selectedProject) {
      this.valid.projectId = false;
    }

    if (!(this.selectedDate?.[0] && this.selectedDate?.[1])) {
      this.valid.date = false;
    }

    if (!this.selectedTemplate) {
      this.valid.template = false;
    }

    if (!this.selectedBrand) {
      this.valid.mainLevel1 = false;
    }

    if (!this.selectedFeatureSet) {
      this.valid.feature = false;
    }

    if (this.showTopics.length === 0) {
      this.valid.topics = false;
    }

    if (!this.subject.trim()) {
      this.valid.subject = false;
    }

    if (this.receiver.length === 0) {
      this.valid.receivers = false;
    }

    if (this.selectedFrequency.length === 0) {
      this.valid.frequency = false;
    }

    if (Object.values(this.valid).some((item) => !item)) {
      return false;
    }

    return true;
  };

  @action onSubmit = async () => {
    if (mainStore.checkIsInQueue('updateEdm')) {
      return;
    }
    mainStore.setLoading('updateEdm');

    // > check data completed
    const checkRes = this.checkStatus();
    if (!checkRes) {
      message.error(i18n.t('edm_setting_drawer_message_error'));
      mainStore.setLoadingComplete('updateEdm');
      return;
    }

    const data = {
      name: this.name,
      ...(!this.id && { projectId: this.selectedProject }),
      collaboratorIds: toJS(this.selectedCollaborators),
      emailStartDate: dayjs(this.selectedDate[0]).tz('Asia/Taipei').startOf('day').toISOString(),
      emailEndDate: dayjs(this.selectedDate[1]).tz('Asia/Taipei').endOf('day').toISOString(),
      frequency: toJS(this.selectedFrequency),
      receivers: toJS(this.receiver),
      // ? added 2024/11/01
      autoSummary: this.autoSummary,
      // ? added 2025/03/28
      aiComment: this.aiComment,
      template: this.selectedTemplate,
      subject: this.subject,
      isAutoSend: this.autoSend,
      isAutoSendAtNoon: this.selectedFrequency.includes('multiple') && this.dailyNoon,
      isAutoSendAtEvening: this.selectedFrequency.includes('multiple') && this.dailyEvening,
      // ? added 2024/12/09
      isAutoSendAtNight: this.selectedFrequency.includes('multiple') && this.dailyNight,
      mainBrandId: (is.null(this.selectedBrand) || is.undefined(this.selectedBrand)) ? null : this.selectedBrand,
      featureSetId: (is.null(this.selectedFeatureSet) || is.undefined(this.selectedFeatureSet)) ? null : this.selectedFeatureSet,
      tags: toJS(this.tagPool.filter((tag) => tag.action.trim()).map((tag) => ({
        id: tag.id,
        value: tag.name,
        type: tag.type,
        action: tag.action
      }))),
      topics: this.topics.filter((topic) => topic.action.trim()).map((topic) => topic.sendContent)
    };

    if (this.id) {
      try {
        await EDMService.updateEDM(this.id, data);

        runInAction(() => {
          message.success(i18n.t('edm_setting_drawer_message_success'));

          this.parent.onRefresh();
          this.onClose();
        });
      } catch (error) {
        if (error.response?.status === 503) {
          message.warning(i18n.t('api_post_inform_update_error'));

          this.parent.onRefresh();
          this.onClose();

          return;
        }
        if (error.response?.status === 409) {
          this.valid.nameDuplicate = false;
        }
        message.error(i18n.t('api_put_update_edm_error'));
      } finally {
        mainStore.setLoadingComplete('updateEdm');
      }
    } else {
      try {
        await EDMService.createEDM(data);

        runInAction(() => {
          message.success(i18n.t('edm_setting_drawer_message_success'));

          this.parent.onRefresh();
          this.onClose();
        });
      } catch (error) {
        if (error.response?.status === 503) {
          message.warning(i18n.t('api_post_inform_update_error'));

          this.parent.onRefresh();
          this.onClose();

          return;
        }

        if (error.response?.status === 409) {
          this.valid.nameDuplicate = false;
        }
        message.error(i18n.t('api_post_create_edm_error'));
      } finally {
        mainStore.setLoadingComplete('updateEdm');
      }
    }

  };

  @action onCheckDeleteModalOpen = (content) => {
    this.checkDeleteModalViewModel.onCheckDeleteModalOpen(content);
  };
}
